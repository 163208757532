import React, {
    useState,
    useMemo,
    useEffect,
    useContext,
    useCallback,
} from "react"
import { NotionPageIcon } from "../ReusableIcons"
import {
TextField,
} from '../Reusable';
import { print } from "../utils";

const Autocomplete = require("@material-ui/lab/Autocomplete").default
const Select = require("@material-ui/core/Select").default
const MenuItem = require("@material-ui/core/MenuItem").default
const { createFilterOptions } = require("@material-ui/lab/Autocomplete")
const filter = createFilterOptions({ limit: 20, trim: true })

const toNotionImageUrl = (url: string) => {
    return `https://notion.so/image/${encodeURIComponent(
      url.startsWith("/image") ? `https://notion.so${url}` : url
    )}`
  }
const toPageImageUrl = (url: string, pageId: string, userId: string) => {
    return `${toNotionImageUrl(
      url
    )}?table=block&id=${pageId}&userId=${userId}&cache=v2`
  }

export const PageIcon = ({
    icon,
    pageId,
    userId,
  }: {
    icon: string | null
    pageId: string
    userId: string
  }) => {
    if (!icon)
      return (
        <div style={{ width: "27px",fill:"currentColor" }}>
          <NotionPageIcon />
        </div>
      )
    return (
      <div style={{ marginRight: "4px", display: "flex", alignItems: "center" }}>
        {icon.startsWith("http") ? (
          <>
            {
              <img
                src={toPageImageUrl(icon, pageId, userId)}
                height="20px"
                width="20px"
              />
            }
          </>
        ) : (
          <>{icon}</>
        )}
      </div>
    )
  }

export const SelectedPageOption = ({
    name,
    icon,
    iconComp,
    userId,
    pageId,
    className,
  }: {
    name: string
    icon?: any
    iconComp?: any
    pageId?: string
    userId?: string
    className?: string
  }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {(iconComp && iconComp) || (
            <PageIcon icon={icon} pageId={pageId} userId={userId} />
          )}
          <div className={className || "notionPage"}>{name}</div>
        </div>
      </>
    )
  }
  export const SelectFieldNoAutoComplete = ({
    value,
    options,
    onSelectOption,
    renderOption,
    uniqueKey,
    style,
    className,
    renderValue,
    disabled,
    onOpen,
    loading,
    displayEmpty,
    renderOptionEmpty,
    renderValueEmpty,
  }: {
    value
    className?: string
    style?: {}
    options
    onSelectOption
    renderOption
    uniqueKey: string
    renderValue
    disabled?: boolean
    onOpen?: any
    loading?:boolean
    displayEmpty?: boolean
    renderOptionEmpty?: () => any
    renderValueEmpty?: () => any
  }) => {
    return (
      <>
        <Select
          disabled={disabled}
          style={style}
          className={className}
          value={value}
          onOpen={onOpen}
          onChange={(ev) => {
            onSelectOption(ev.target.value)
          }}
          renderValue={(id) => {
            if (renderValueEmpty && id == "") return renderValueEmpty()
            return renderValue(id)
          }}
          displayEmpty={displayEmpty}
        >
          {renderOptionEmpty && (
            <MenuItem value="">{renderOptionEmpty()}</MenuItem>
          )}
          {options.map((opt) => (
            <MenuItem key={opt[uniqueKey]} value={opt[uniqueKey]}>
              {renderOption(opt)}
            </MenuItem>
          ))}
          {(options.length == 0 && loading) && <div style={{margin:'8px'}}>...loading</div>}
        </Select>
      </>
    )
  }
  const TextFieldFocuser = ({params, onOpen, onClose, isDefaultOpen, ...restProps}:{params:{inputProps:any} & any, onOpen, onClose; isDefaultOpen?:boolean} & any) => {
    const elRef = React.useRef(null)
    const [open, setOpen] = useState(false)
    const [longOpen, setLongOpen] = useState(false)
    const [focus, setFocus] = useState(false)
    
    const handleEvent = (ev) => {
      const putBlur = () => {
        setOpen(false)
        onClose();
        setLongOpen(false)
        setFocus(false)
        elRef.current.blur()
      }
      const putFocus = () => {
        setOpen(true)
        onOpen();
        setFocus(true)
        elRef.current.focus()
      }
      ev.preventDefault()
      ev.stopPropagation()
      switch (ev.type) {
        case "focus":
          putFocus()
          break
        case "blur":
          putBlur()
          break
        default:
          if (focus == true) {
            putBlur()
          } else {
            if ([...(ev.target?.classList || [])].includes("MuiSvgIcon-root")) {
              setLongOpen(true)
            }
            putFocus()
          }
      }
      return false
    }
  
    useEffect(()=>{
      if (isDefaultOpen){
        setTimeout(()=>{
          elRef.current?.focus();
        },50);
      }
    },[]);
  
    return  <TextField
    {...params}
    focused={focus}
    onClick={handleEvent}
    onMouseUp={handleEvent}
    onMouseDown={handleEvent}
    onBlur={handleEvent}
    onFocus={handleEvent}
    inputRef={elRef}
    {...restProps}
    inputProps={{
      ...params.inputProps,
      onBlur: handleEvent,
      onFocus: handleEvent,
      onMouseDown: handleEvent,
      onMouseUp: handleEvent,
    }}
    variant="outlined"
  />;
  }
  

export const AutocompleteField = ({
    value,
    onChangeValue,
    options,
    renderOption,
    textClass,
    textStyle,
    size,
  }: {
    value,
    onChangeValue,
    options,
    renderOption,
    textClass?: string
    textStyle?: any
    size?: "small" | "medium"
  }) => {
    const [open,setOpen] = useState(false);
    //const inputRef = React.useRef(null)
    //const [focus, setFocus] = useState(false)
    const [entries, setEntries] = useState(value ? [value] : [])

    useEffect(()=>{
      if (entries.length == 1 && entries[1] != value) {
        setEntries([value]);
      }
    },[value])

    return (
      <>
        <Autocomplete
          multiple
          disableClearable
          open={open}
          value={entries}
          options={options}
          getOptionLabel={(option) => option.value}
          size={size}
          blurOnSelect
          renderOption={(option) => {
            return (<>
                  {renderOption(option)}
            </>
            )
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index })
              return (
                <React.Fragment
                  key={key}
                  data-tag-index={tagProps["data-tag-index"]}
                >
                  {renderOption(option)}
                </React.Fragment>
              )
            })
          }
          filterOptions={(options, params) => {
            let text = params.inputValue.toLowerCase();
            return options.filter(opt => opt.name.toLowerCase().indexOf(text) != -1);
          }}
          style={{}}
          onChange={async (
            ev,
            options,
            reason:
              | "create-option"
              | "select-option"
              | "remove-option"
              | "blur"
              | "clear"
          ) => {
            const opts: any[] = options.map((opt) => {
              return opt
            })
  
            let v
            if (opts.length == 0) {
              v = null
              setEntries([])
            } else {
              v = opts[opts.length - 1]
              setEntries([opts[opts.length - 1]])
            }
            onChangeValue(v)
          }}
          renderInput={(params) => (
              <TextFieldFocuser 
              params={params}
              onOpen={()=>setOpen(true)}
              onClose={()=>setOpen(false)}
              placeholder={entries.length == 0 ? "Empty" : ""}
              className={textClass || undefined}
              style={textStyle || {}}
              />
          )}
        />
      </>
    )
  }