import React, {
  CSSProperties,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Router } from "@reach/router"
import { Link } from "gatsby"
import "../../app.css"
// prettier-ignore
import {
      Box, Boxfc, Boxf, BoxPaper, Hidden,  Content,BoxProps,Chip,
      BoxCont, BoxVert, BoxHori, TextField,
      H4, H1, H3, H2,Txt,
      Button,Touchable, Switch, Divider,Avatar,
      Opener,
      Br,
      Cols,Rows,
      DialogTitle,Dialog, LinkButton,noScreenShotImageUrl,LinkBox,CircularProgress,
      Todo,SectionBox, PropertyFieldBox, Loading, ButtonBox, Nh4, PropertyFieldAction, useDataSync
} from "../../Reusable";
import { print, jdump } from "../../utils"
//import { glMutate, useQuery } from "../../graphqlUtils"
import { getNotionWebsiteIdFromUrl } from "../../notionUtils"
import { KeyboardArrowRightIcon } from "../../ReusableIcons"
import {
  AppSyncContext,
  useQuery,
  query,
  mutation,
} from "../../providers/AppSync"
import * as t from "../../generated/apiTypes"
import { User } from "../../types"
import { useApiData } from "../../pages/dashboard"

const PaymentInfo = ({}: {}) => {
  return (
    <>
      <SectionBox name="Payment Info">todo2</SectionBox>
    </>
  )
}

const UserEmailFields = ({ user }: { user: User}) => {
  const [email, setEmail] = useState(user.email)

  return (
    <>
      <Box color="grey">
        <PropertyFieldAction
        actionComp={
          <Button variant="outlined" disabled>update email</Button>
        }
        labelWidth="100px"
        inputWidth="250px"
        label="Email"
        placeholder="Email"
        value={user.email}
        onChangeValue={setEmail}
        disabled
        />
        <PropertyFieldAction
          labelWidth="100px"
        inputWidth="250px"
        label="Password"
          type="password"
          placeholder="password"
          value={"password"}
          disabled
          actionComp={
          <Button variant="outlined" disabled>update password</Button>
          }
        />
      </Box>
    </>
  )
}

const UserGoogleFields = ({ user }: { user: User }) => {
  const [email, setEmail] = useState(user.email)

  return (
    <>
      <Box maxWidth="700px">
        <PropertyFieldBox
          labelWidth="150px"
          label="Email"
          placeholder="Email"
          value={user.email}
          onChangeValue={setEmail}
          disabled
        />
        <Br />
        <Cols align="center">
          <Cols height="39px" mr={1.5} align="center">
            <img src="/google-logo.svg" />
          </Cols>
          <Txt>Account created with google</Txt>
        </Cols>
      </Box>
    </>
  )
}

/*
const NotionAuthTokenField = ({ user,syncUser }: { user: User,syncUser:(u:Partial<User> & {id:string})=>void }) => {
  const [notionToken, setnotionToken] = useDataSync(user.notion?.tokenV2, (v)=>syncUser({id:user.id,notion:{
    userId:user.notion?.userId,
    tokenV2:v}}))
  const [notionUserId, setnotionUserId] = useDataSync(user.notion?.userId, (v)=>syncUser({id:user.id,notion:{
      tokenV2:user.notion?.tokenV2,
    userId:v
  }}))
  return (<>
  <Rows>
    <Box maxWidth="700px">
        <PropertyFieldBox
          labelWidth="150px"
          label="Notion Token"
          placeholder="Notion Token V2"
          value={notionToken}
          onChangeValue={setnotionToken}
        />
      </Box>
      <Box maxWidth="700px">
        <PropertyFieldBox
          labelWidth="150px"
          label="Notion User Id"
          placeholder="Notion User Id"
          value={notionUserId}
          onChangeValue={setnotionUserId}
        />
      </Box>
    </Rows>
  </>);
}*/

const NotionInfo = ({user}:{user:User}) => {
  if (!user.authToNotion) return <Txt>Not connected to Notion</Txt>
  return (<>
  <Cols align="center">
          <Cols height="39px" mr={1.5} align="center">
            <img src={user.notionUser.profilePhoto} height="100%" width="auto"/>
          </Cols>
  <Txt>Connected as {user.notionUser.givenName} {user.notionUser.familyName}</Txt>
        </Cols>
  </>);
}

export const Profile = ({ path }: { path?: string }) => {
  const {loading,user} = useApiData();
  if (loading) return <Loading />
  return (
    <Rows>
      <SectionBox name="User Info">
        {user.source == "email" ? (
          <UserEmailFields user={user} />
        ) : (
          <UserGoogleFields user={user} />
        )}
      </SectionBox>
      
      <SectionBox name="Notion Credentials">
          <NotionInfo user={user} />
      </SectionBox>

      <SectionBox name="Stripe">
        <Todo />
      </SectionBox>
      <SectionBox name="Danger Zone">
        <Rows mt={2}>
          <Box mb={1}>
            <Nh4>Close your account</Nh4>
          </Box>

          <Box>
            <Button color="secondary" disabled>
              Close
            </Button>
          </Box>
        </Rows>
      </SectionBox>
    </Rows>
  )
}

export default Profile
