import React, {
  CSSProperties,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react"
import { Router } from "@reach/router"
import "../app.css"
//import "../theme.css"
import Analytics from '@aws-amplify/analytics';
// prettier-ignore
import {
      Box, Boxfc, Boxf, BoxPaper, Hidden,  Content,BoxProps,
      BoxCont, BoxVert, BoxHori, TextField,
      H4, H1, H3, H2,Txt,
      Button,Touchable, Switch, Divider,Avatar,
      Opener,
      Br,
      Cols,Rows,
      DialogTitle,Dialog, LinkButton,noScreenShotImageUrl,LinkBox,CircularProgress, SectionBox, PaperBox, Chip, DialogProvider, EditableText, ActionsButton, IconButton, Nhr, DialogContext, useDataSync, useEff, Loading, Space, PropertyCheckBoxFieldBox, Property, SelectField, notionColors, notionColorsArr, ErrorMsgBox, ButtonErr, ToolTipOpener
  } from "../Reusable";
import { print, jdump, timeAgo, timestamp, isCurrentlySSR } from "../utils"
import {
  AppSyncContext,
  useQuery,
  ApiClient,
} from "../providers/AppSync"
import * as mutation from "../generated/op/mutations"
import * as query from "../generated/op/queries"
import * as sub from "../generated/op/subscriptions"
import * as t from "../generated/apiTypes"

//import { glMutate, useQuery } from "../../graphqlUtils"
//import { loadStripe } from "@stripe/stripe-js"
import {
  DeleteIcon,
  EditIcon,
  KeyboardArrowRightIcon,
  LinkIcon,
  MoreHorizIcon,
} from "../ReusableIcons"
import { Layout } from "../components/Layout"
import ProfilePage from "../components/pages/Profile"
import { Form, Workspace } from "../types"
//import { STRIPE_API_KEY } from "../config"
import { SortableNotionLikeList } from "../components/SortableNotionLikeList"
import { CollectionSelect, getDepVal, SpaceSelect } from "../components/CollectionSelect"
import { AutocompleteField } from "../components/Field"
const Helmet = require("react-helmet").default
const Tooltip = require('@material-ui/core/Tooltip').default;

const DialogContent = ({ children, onClose, ...restProps }) => {
  return (
    <>
      <Dialog
        onClose={onClose}
        {...restProps}
        maxWidth="lg"
        classes={{
        }}
      >
        <Rows width="100%">{children}</Rows>
      </Dialog>
    </>
  )
}

function useStateCache<Val = any>(
  key: string,
  defaultValue: Val
): [Val, React.Dispatch<React.SetStateAction<Val>>] {
  const x = useState<Val>(
    (JSON.parse(localStorage.getItem(key)) as Val) || defaultValue
  )
  const timeout = React.useRef(null)
  const val = React.useRef(null)
  val.current = x[0]

  useEffect(() => {
    //save in cache
    if (timeout.current) return
    timeout.current = setTimeout(() => {
      localStorage.setItem(key, JSON.stringify(val.current))
      timeout.current = null
    }, 100)
  }, [x[0]])

  return x
}

async function callNotion(command:"getWorkspaces"|"searchCollections", params, {api,form}:{form:Form,api:ApiClient}){
  const resp = await api.gl(mutation.callNotion, {form:{id:form.id,secretToken:form.secretToken}, notion:{command,params:JSON.stringify(params)}});
  if (resp.success == false) return null;
  print('ret',resp.data);
  return JSON.parse(resp.data);
}


const CollectionFields = ({onGoBack}:{onGoBack}) => {
  const {api,} = useAppContext()
  const {form,syncForm} = useApiData();
  const [collection, setCollection] = useDataSync(form.collection, (collection)=>{
    const name = getDepVal(collection, "name", "Untitled");
    syncForm({id:form.id,collection,fields:[
      {"kind":"data","key":"aTga","options":{"defaultValue":""},"fieldOptions":{"locked":false},"property":{"valid":true,"name":"Name","id":"title","type":"title"}},
    ],name:`${name} Form`})
  })
  const [workspaces, setWorkspaces] = useStateCache<Workspace[]>("workspaces", [])
  const [workspace, setWorkspace] = useState(form.collection?.workspace || null)
  const refreshWorkspaces = async () => {
    setWorkspaces(await callNotion("getWorkspaces",{}, {api,form}) || [])
  }
  useEffect(() => {
    if (workspace == null && workspaces.length) {
      setWorkspace(workspaces[0]);
    }
  }, [workspaces])
  
  useEff(async ()=>{
    if (workspaces.length == 0) {
      refreshWorkspaces();
    }
  }, [])

  if (workspace == null)
    return <Loading />
  return (<Rows fontSize="16px">
    <Box mb={1} class="notionDark" >
                          <SpaceSelect
                            workspaces={workspaces}
                            spaceId={workspace.id}
                            refreshWorkspaces={refreshWorkspaces}
                            onSelectSpaceId={(spaceId) => {
                              const workspace = workspaces.find(e => e.id == spaceId);
                              if (collection?.workspace){
                                if (collection?.workspace.id != spaceId){
                                  setCollection(null);
                                }
                              }
                              setWorkspace(workspace);
                              /*
                              if (data.spaceId != spaceId) {
                                updateData({ spaceId, collection: undefined })
                                if (formId) {
                                  print("delete formId")
                                  deleteFormData(formId)
                                }
                              }*/
                            }}
                          />
                        </Box>
                        <Br/>
                        <Box>
                          <CollectionSelect
                            disabled={false}
                            workspace={workspace}
                            collection={collection}
                            userId={""}
                            searchCollections={async (query,w)=>{
                              if (!w) return [];
                              return (await callNotion("searchCollections",{query,spaceId:w.id}, {api,form})) || [] 
                            }}
                            onSelectCollection={async (collection) => {
                              if (collection == null){ setCollection(null); return ;}
                              setCollection({...collection, workspace})
                            }}
                          />
                        </Box>

<Box mt={2}>
                      <Button disabled={collection == null} onClick={()=>onGoBack()} size="large">Ok</Button>
                    </Box>
  </Rows>);
}

const FormChooseColDialog = ({form,onClose,onGoBack, ...restProps }:{form:Form} & any) => {
  return (<>
    <Dialog
        onClose={onClose}
        {...restProps}
        maxWidth="md"
      >
        <Rows width="100%">
        <DialogTitle>
          <Rows>
            <H2 mb={2}>Choose a database</H2>
            <CollectionFields onGoBack={onGoBack} />
          </Rows>
          
          </DialogTitle>
        </Rows>
    </Dialog>
  </>);
}

const Widget = ({form}:{form:Form}) => {
  return (<Box border="1px dashed"
  minHeight="100px"
  >
    <iframe src={`https://d2sg8tr23sb2ky.cloudfront.net/widget/${form.id}`}>
    </iframe>
  </Box>);
}

const FormCollectionLocation = ({form}:{form:Form}) => {
  return (<>
          <div >{form.collection?.name} <span style={{color:"--var(fg-color-1)"}}>on</span> {form.collection?.workspace.name}</div> 
    
  </>);
}
const AdvancedOptions = ({form,openCollectionSetting}:{form:Form,openCollectionSetting}) => {
  const {
    syncForm
  } = useApiData()
  const [options, setOptions] = useDataSync(form.options || {darkMode:false,color:'blue',locked:false}, (options)=>syncForm({id:form.id,options}))
  const [isMulti, setIsMulti] = useDataSync(form.type == t.FormType.multipleForm || false, (isMulti)=>syncForm({id:form.id,type:isMulti ? t.FormType.multipleForm : t.FormType.singleForm}))

  return (<Rows>
    <Space />
    <H4>Advanced Settings</H4>
    <Space />
    <Property
    label={"Database"}
    labelWidth={"150px"}
    >
      <Touchable onClick={()=>openCollectionSetting()}>
        <Cols align="center" className="propertyFieldBox">
          <FormCollectionLocation form={form}/>
          <Box ml={1}>
      <IconButton>
                            <EditIcon fontSize="small" />
                          </IconButton>
                          </Box>
                          </Cols>
                          </Touchable>
    </Property>
    {/*<Space />
    
    <PropertyCheckBoxFieldBox 
    label={"Lock Edition 🔒"}
    value={options.locked || false}
    onChangeValue={(locked)=>setOptions({...options,locked})}
    labelWidth={"150px"}
    />*/}
    
    <Space />
    
    <PropertyCheckBoxFieldBox 
    label={"Dark Mode 🌙"}
    value={options.darkMode}
    onChangeValue={(darkMode)=>setOptions({...options,darkMode})}
    labelWidth={"150px"}
    />
    
    <Space />

<Property
        label={"Color"}
        labelWidth={"150px"}
    >
      <SelectColorField color={options.color || "blue"} onChangeColor={(color)=>setOptions({...options,color})} />
    </Property>

    <Space />

    <PropertyCheckBoxFieldBox 
    label={"Multiple Forms"}
    value={isMulti}
    onChangeValue={setIsMulti}
    labelWidth={"150px"}
    />

  </Rows>);
}


type Color = (typeof notionColorsArr)[0]

const SelectColorField = ({color,onChangeColor}:{color:Color["name"], onChangeColor:(v)=>void}) => {
  return (<>
  <SelectField
  className="propertyField" 
  style={{width:"250px"}}
  value={{name:color, color:notionColors[color]}}
  options={notionColorsArr}
  onSelectOption={(col)=>onChangeColor(col?.name || "blue")}
  renderOption={(col)=><Chip 
    label={col.name}
    color={col.name}
  />}
  uniqueKey={"name"}
  />
  </>);
}

const CopyWidgetButton = ({id, size, variant,disabled}:{id:string,size?, variant?,disabled?}) => {
  return (<Rows>
      <ToolTipOpener>
        {({openTooltip})=><>
        <div>

          <Button size={size || "large"} variant={variant || "contained"} disabled={disabled} onClick={(e) => {
                 e.stopPropagation();
                  navigator.clipboard.writeText(`widget.nono.so/forms/index.html?id=${id}`)
                  openTooltip("copied to clipboard!",2000)
                }} endIcon={<LinkIcon/>}>Copy Widget URL</Button>

        </div>
        </>}        
        </ToolTipOpener>
  </Rows>);
}

const _FormDialog = ({form,onClose, ...restProps }:{form:Form,onClose} & any) => {
  const {
    showScreenLoading,
    hideScreenLoading,
    api
  } = useAppContext()
  const {removeForm,
  } = useApiData()
  const [status, setStatus] = useState<"home"|"chooseDb">(form.collection == null ? "chooseDb" : "home")
  if (status == "chooseDb"){
    return <FormChooseColDialog onGoBack={()=>setStatus("home")} form={form} onClose={onClose} {...restProps}/>;
  }
  return (
    <DialogContent onClose={onClose} {...restProps}>
      <DialogTitle>
          <Rows ml={3}>
            <Cols mb={2} width="100%" justify="space-between" align="center">
              <Cols align="center">
                <H3>{form.name}</H3>
                <Box ml={2}>
                  <>
                    <ActionsButton
                      actions={[{ name: "Remove", icon: DeleteIcon }]}
                      buttonComp={(props) => (
                        <>
                          <IconButton  {...props}>
                            <MoreHorizIcon />
                          </IconButton>
                        </>
                      )}
                      onClickAction={async (action) => {
                        if (action == "Remove") {
                          showScreenLoading("removing")
                          await removeForm(form.id)
                          onClose()
                          hideScreenLoading();
                        }
                      }}
                    />
                  </>
                </Box>
              </Cols>
              <Box>
                <CopyWidgetButton id={form.id} />
              </Box>
            </Cols>
          </Rows>
      </DialogTitle>
      <Nhr />
      <Rows ml={4} mt={4}>
          {/*<Widget form={form}/>*/}
          <Space />
          <AdvancedOptions form={form} openCollectionSetting={()=>setStatus("chooseDb")}/>
      </Rows>
    </DialogContent>
  )
}

const FormDialog = ({ data:{id}, onClose, ...restProps }: { data: {id:string} } & any) => {
  const {
    showScreenLoading,
    hideScreenLoading,
    api
  } = useAppContext()

  const {form,setCurrentForm,removeCurrentForm,syncForm} = useApiData();
  useEffect(()=>{
    setCurrentForm(id);
  },[]);
  if (form == null) {
    return <></>;
  }
  return <_FormDialog form={form} onClose={()=>{
    removeCurrentForm()
    onClose()
  }} {...restProps} />;
}


//export const stripePromise = loadStripe(STRIPE_API_KEY)

export const ListForms = ({
  forms,
  onOpenForm,
}: {
  onOpenForm?: (f: Form) => void
  forms: any[]
}) => {
  return (
    <Cols flexWrap="wrap">
      {forms.map((e) => (
        <FormBox form={e} key={e.id} onOpenForm={onOpenForm} mr={2} mb={2}/>
      ))}
    </Cols>
  )
}

const NotionLogin = ({}:{}) => {
  const {
    openDialog,
    user,
    api,
    showScreenLoading,
    hideScreenLoading,
    showInvalidResp,
  } = useAppContext()
  const [notionToken, setNotionToken] = useState("")
  const [error, setError] = useState(null)
  return (<Rows>
    <H2>To use our widgets, please add your Notion credentials</H2>
    <Space />
    <Txt>Get the Notion credentials (auth token) by downloading our Chrome extension <a style={{color:'inherit',fontSize:'bold'}} href="https://chrome.google.com/webstore/detail/notion-forms/lmlcnllajejljjffbfnpcmeldomdcfjn" target="_blank">here</a></Txt>
    <Space />
    <TextField 
    multiline
    placeholder="paste here your Notion credentials"
    value={notionToken}
    onChange={(e)=>{setNotionToken(e.target.value); setError(null);}}
    />
    <Space />
    <Box>
      <Button onClickAwait={async ()=>{
        await showScreenLoading("Establishing a connection with Notion...")
        const resp = await api.gl(mutation.addNotionAuthToken, {notionAuthToken:notionToken})
        await hideScreenLoading()
        if (resp.success == false){
          throw resp.errorMsg;
        } else {
          //change
          window.location.reload();
        }
      }}>Connect To Notion</Button>
    </Box>

    <Space />
    <Txt>⚠️ by doing so, we store your credentials on our servers (hosted on Amazon Web Services). We promise we will never use it, but if you have some sensitive informations you can consider creating another Notion account and connect with it instead!</Txt>
    <Space />

  </Rows>);
}

export const DashboardPage = (props: any) => {
  const {
    openDialog,
    api,
    showScreenLoading,
    hideScreenLoading,
    showInvalidResp,
  } = useAppContext()
  const {loading,forms,user,addForm} = useApiData()
  //const x = useQuery(query.listforms, {})
  if (loading) return <Loading />;
  if (!user.authToNotion) return <NotionLogin />
  return (
    <>
      <SectionBox
        name="Forms"
        actionComp={
          <Button
            onClickAwait={async () => {
              await showScreenLoading("creating a new form")
              const id = await addForm()
              await hideScreenLoading()
              openDialog(FormDialog, {id})
            }}
            size="small"
          >
            New Form
          </Button>
        }
      >
        {loading && <>loading...</>}
        {forms.length == 0 && !loading && <>no forms, please add a new one</>}
        <ListForms
          forms={forms}
          onOpenForm={(form) => openDialog(FormDialog, form)}
        />
      </SectionBox>
    </>
  )
}

const useAppContext = () => {
  const { openDialog } = useContext(DialogContext)
  const { user, api, startLoading, stopLoading } = useContext(AppSyncContext)
  return {
    openDialog,
    user,
    api,
    showInvalidResp: (resp) => {
      console.log("ERROR", resp)
    },
    showScreenLoading: startLoading,
    hideScreenLoading: stopLoading,
  }
}
const FormBox = ({form,onOpenForm,...bprops}:{form:Form;onOpenForm} & BoxProps) => {
  return (<BoxPaper elevation={6} width="100%" maxWidth="300px" style={{cursor:'pointer',borderTop:'12px solid '+(form.options?.color ? notionColors[form.options.color] : notionColors.blue),borderRadius:'3px'}} onClick={() => onOpenForm(form)} {...bprops}>
    <Rows minHeight="100px"  p={2}>
    <H4>{form.name}</H4>
    <Txt>{form.collection && <FormCollectionLocation form={form}/> || <i>No selected database</i>}</Txt>
    <Box flex={1}></Box>
    <Cols><Box flex={1}></Box>
    <CopyWidgetButton id={form.id} variant="outlined" size="small" disabled={form.collection == null}/>
    </Cols>
  </Rows>
  </BoxPaper>);
}

export const Dashboard = (props: any) => {
  print("dashbaord")
  console.log("ok1")
  if (isCurrentlySSR()) return <></>
  console.log("ok2")
  print("done dahsboard")
  return (
    <Layout location={props.location}>
      <style></style>
      <Helmet>
        <title>forms.nono.so</title>
      </Helmet>
      <ApiDataProvider>
      <DialogProvider>
        <Router>
          <DashboardPage path="/dashboard" />
          <ProfilePage path="/dashboard/profile" />
        </Router>
      </DialogProvider>
      </ApiDataProvider>
    </Layout>
  )
}

function useApiDataSub(){
    const { user, api, startLoading, stopLoading } = useContext(AppSyncContext)
    const { loading, data } = useQuery(
      query.getUser,
      {
        id:user.id,
      },
      ["forms","not:notionAuthToken" as any],
      [
        {
          op: sub.onUpdateForm,
          variables: {
            userId: user.id,
          },
          next: (updatedForm, user) => {
            print("received update",updatedForm,user)
            let found = false
            const forms = user.forms.items.map((f) => {
              if (updatedForm.id == f.id) {
                found = true
                return updatedForm
              }
              return f
            })
            if (!found) {
              forms.push(updatedForm)
            }
            return {
              ...user,
              forms:{...user.forms,items:forms},
            }
            //return data;
          },
          //variables:{userId:websiteId},
        },
      ]
    )
    const [forms, setforms] = useState([])
    const [form, setForm] = useState(null)
  
    useEffect(() => {
      if (!data) {
        setforms([])
        return
      }
      setforms(
        data.forms.items.map(fx => {
          const f = ({...fx,
          collection:fx.collection != null ? JSON.parse(fx.collection) : null,
          fields:fx.fields != null ? JSON.parse(fx.fields) : [],
        })
        if (f.id == form?.id){
          setForm(f);
        }
        return f;
      }).sort(
          (a, b) => timestamp(a.createdAt) - timestamp(b.createdAt)
        )
      )
    }, [data])
    
    return {loading, forms,form,setCurrentForm:(id:string)=>setForm(forms.find(e => e.id == id)),removeCurrentForm:()=>setForm(null), user:data as any,
    syncForm:async (f:Partial<Form> & {id:string})=>{
        setForm(form =>({...form,...f}));
        function withJdump(f,keys = []){
          let input:any = {...f}
          keys.forEach(k => {
            if (input[k]) input[k] = JSON.stringify(input[k]);
          })
          return input;
        }
        await api.gl(mutation.updateForm, {
          input:withJdump(f,['collection','fields','data']),
        })
    },
    removeForm:async (formId)=>{
      print("vs",forms,formId,forms.filter(e => e.id != formId));
      await api.gl(mutation.removeForm, {formId})
      setforms(forms.filter(e => e.id != formId));
    },
    addForm:async ()=>{
      const resp = await api.gl(mutation.startForm, {})
      Analytics.record({ name: 'addNewForm' });
      setforms(forms => ([...forms,resp.data]))
      return resp.data?.id;
    },
  };
}
export const useApiData = ()=>React.useContext(ApiDataContext);

const ApiDataContext = React.createContext<ReturnType<typeof useApiDataSub>>(null as any)
function ApiDataProvider({children}){
  const p = useApiDataSub()
return <ApiDataContext.Provider value={p}>{children}</ApiDataContext.Provider>
}

export default Dashboard
