import {OnCreateAppSubscription,OnUpdateAppSubscription,OnDeleteAppSubscription,OnCreateFormSubscriptionVariables,OnCreateFormSubscription,OnUpdateFormSubscriptionVariables,OnUpdateFormSubscription,OnDeleteFormSubscriptionVariables,OnDeleteFormSubscription} from '../apiTypes';

export interface ExtSubscription<Var,Resp> {
  name:string;
  content:string;
};

export const onCreateApp:ExtSubscription<{},OnCreateAppSubscription["onCreateApp"]> = {name:"onCreateApp", content:`
  subscription OnCreateApp {
    onCreateApp {
      id
      usersCount
      createdAt
      updatedAt
    }
  }
`}
export const onUpdateApp:ExtSubscription<{},OnUpdateAppSubscription["onUpdateApp"]> = {name:"onUpdateApp", content:`
  subscription OnUpdateApp {
    onUpdateApp {
      id
      usersCount
      createdAt
      updatedAt
    }
  }
`}
export const onDeleteApp:ExtSubscription<{},OnDeleteAppSubscription["onDeleteApp"]> = {name:"onDeleteApp", content:`
  subscription OnDeleteApp {
    onDeleteApp {
      id
      usersCount
      createdAt
      updatedAt
    }
  }
`}
export const onCreateForm:ExtSubscription<OnCreateFormSubscriptionVariables,OnCreateFormSubscription["onCreateForm"]> = {name:"onCreateForm", content:`
  subscription OnCreateForm($userId: String) {
    onCreateForm(userId: $userId) {
      id
      createdAt
      updatedAt
      userId
      type
      name
      secretToken
      data
      fields
      collection
      options {
        darkMode
        color
        locked
      }
    }
  }
`}
export const onUpdateForm:ExtSubscription<OnUpdateFormSubscriptionVariables,OnUpdateFormSubscription["onUpdateForm"]> = {name:"onUpdateForm", content:`
  subscription OnUpdateForm($userId: String) {
    onUpdateForm(userId: $userId) {
      id
      createdAt
      updatedAt
      userId
      type
      name
      secretToken
      data
      fields
      collection
      options {
        darkMode
        color
        locked
      }
    }
  }
`}
export const onDeleteForm:ExtSubscription<OnDeleteFormSubscriptionVariables,OnDeleteFormSubscription["onDeleteForm"]> = {name:"onDeleteForm", content:`
  subscription OnDeleteForm($userId: String) {
    onDeleteForm(userId: $userId) {
      id
      createdAt
      updatedAt
      userId
      type
      name
      secretToken
      data
      fields
      collection
      options {
        darkMode
        color
        locked
      }
    }
  }
`}