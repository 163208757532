/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum ErrorCode {
  checkLogin_maximum_users = "checkLogin_maximum_users",
  runtime_error = "runtime_error",
  unknown_dev_error = "unknown_dev_error",
  unauthorized = "unauthorized",
  invalid_input = "invalid_input",
}


export enum FormType {
  singleForm = "singleForm",
  multipleForm = "multipleForm",
}


export type ModifiableFormInput = {
  id: string,
  secretToken?: string | null,
  name?: string | null,
  fields?: string | null,
  type?: FormType | null,
  data?: string | null,
};

export type ModifiableUserInput = {
  id: string,
  notion?: NotionObjInp | null,
};

export type NotionObjInp = {
  tokenV2?: string | null,
  userId?: string | null,
};

export enum UserSource {
  email = "email",
  google = "google",
}


export enum UserType {
  pro = "pro",
  free = "free",
}


export type VerifyFormInput = {
  id: string,
  secretToken: string,
};

export type NotionInput = {
  command: string,
  params: string,
};

export type CreateAppInput = {
  id?: string | null,
  usersCount?: number | null,
};

export type ModelAppConditionInput = {
  usersCount?: ModelIntInput | null,
  and?: Array< ModelAppConditionInput | null > | null,
  or?: Array< ModelAppConditionInput | null > | null,
  not?: ModelAppConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type UpdateAppInput = {
  id: string,
  usersCount?: number | null,
};

export type DeleteAppInput = {
  id?: string | null,
};

export type CreateUserInput = {
  id?: string | null,
  source?: UserSource | null,
  type?: UserType | null,
  email?: string | null,
  stripe?: StripeInput | null,
  notionUser?: NotionUserInput | null,
  notionAuthToken?: string | null,
  authToNotion?: boolean | null,
  formsCount?: number | null,
};

export type StripeInput = {
  id?: string | null,
};

export type NotionUserInput = {
  id?: string | null,
  familyName?: string | null,
  givenName?: string | null,
  profilePhoto?: string | null,
};

export type ModelUserConditionInput = {
  source?: ModelUserSourceInput | null,
  type?: ModelUserTypeInput | null,
  email?: ModelStringInput | null,
  notionAuthToken?: ModelStringInput | null,
  authToNotion?: ModelBooleanInput | null,
  formsCount?: ModelIntInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserSourceInput = {
  eq?: UserSource | null,
  ne?: UserSource | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type DeleteUserInput = {
  id?: string | null,
};

export type CreateFormInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId: string,
  type?: FormType | null,
  name: string,
  secretToken?: string | null,
  data?: string | null,
  fields?: string | null,
  collection?: string | null,
  options?: FormOptionInput | null,
};

export type FormOptionInput = {
  darkMode?: boolean | null,
  color?: string | null,
  locked?: boolean | null,
};

export type ModelFormConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelFormTypeInput | null,
  name?: ModelStringInput | null,
  secretToken?: ModelStringInput | null,
  data?: ModelStringInput | null,
  fields?: ModelStringInput | null,
  collection?: ModelStringInput | null,
  and?: Array< ModelFormConditionInput | null > | null,
  or?: Array< ModelFormConditionInput | null > | null,
  not?: ModelFormConditionInput | null,
};

export type ModelFormTypeInput = {
  eq?: FormType | null,
  ne?: FormType | null,
};

export type DeleteFormInput = {
  id?: string | null,
};

export type UpdateUserInput = {
  id: string,
  source?: UserSource | null,
  type?: UserType | null,
  email?: string | null,
  stripe?: StripeInput | null,
  notionUser?: NotionUserInput | null,
  notionAuthToken?: string | null,
  authToNotion?: boolean | null,
  formsCount?: number | null,
};

export type UpdateFormInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  userId?: string | null,
  type?: FormType | null,
  name?: string | null,
  secretToken?: string | null,
  data?: string | null,
  fields?: string | null,
  collection?: string | null,
  options?: FormOptionInput | null,
};

export type ModelAppFilterInput = {
  id?: ModelIDInput | null,
  usersCount?: ModelIntInput | null,
  and?: Array< ModelAppFilterInput | null > | null,
  or?: Array< ModelAppFilterInput | null > | null,
  not?: ModelAppFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  source?: ModelUserSourceInput | null,
  type?: ModelUserTypeInput | null,
  email?: ModelStringInput | null,
  notionAuthToken?: ModelStringInput | null,
  authToNotion?: ModelBooleanInput | null,
  formsCount?: ModelIntInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelFormFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  type?: ModelFormTypeInput | null,
  name?: ModelStringInput | null,
  secretToken?: ModelStringInput | null,
  data?: ModelStringInput | null,
  fields?: ModelStringInput | null,
  collection?: ModelStringInput | null,
  and?: Array< ModelFormFilterInput | null > | null,
  or?: Array< ModelFormFilterInput | null > | null,
  not?: ModelFormFilterInput | null,
};

export type TestMutationVariables = {
  ok?: string | null,
};

export type TestMutation = {
  test:  {
    __typename: "RespStatus",
    success: boolean,
    errorCode: ErrorCode | null,
    errorMsg: string | null,
  },
};

export type StartFormMutation = {
  startForm:  {
    __typename: "RespStatusForm",
    success: boolean,
    errorCode: ErrorCode | null,
    errorMsg: string | null,
    data:  {
      __typename: "Form",
      id: string,
      createdAt: string,
      updatedAt: string,
      userId: string,
      type: FormType | null,
      name: string,
      data: string | null,
      fields: string | null,
      collection: string | null,
      options:  {
        __typename: "FormOption",
        darkMode: boolean | null,
        color: string | null,
        locked: boolean | null,
      } | null,
      secretToken: string | null,
    } | null,
  },
};

export type ModifyFormMutationVariables = {
  form: ModifiableFormInput,
};

export type ModifyFormMutation = {
  modifyForm:  {
    __typename: "RespStatus",
    success: boolean,
    errorCode: ErrorCode | null,
    errorMsg: string | null,
  },
};

export type RemoveFormMutationVariables = {
  formId: string,
};

export type RemoveFormMutation = {
  removeForm:  {
    __typename: "RespStatus",
    success: boolean,
    errorCode: ErrorCode | null,
    errorMsg: string | null,
  },
};

export type ModifyUserMutationVariables = {
  user: ModifiableUserInput,
};

export type ModifyUserMutation = {
  modifyUser:  {
    __typename: "RespStatus",
    success: boolean,
    errorCode: ErrorCode | null,
    errorMsg: string | null,
  },
};

export type AddNotionAuthTokenMutationVariables = {
  notionAuthToken: string,
};

export type AddNotionAuthTokenMutation = {
  addNotionAuthToken:  {
    __typename: "RespStatus",
    success: boolean,
    errorCode: ErrorCode | null,
    errorMsg: string | null,
  },
};

export type CheckLoginMutationVariables = {
  email: string,
};

export type CheckLoginMutation = {
  checkLogin:  {
    __typename: "RespStatusUser",
    success: boolean,
    data:  {
      __typename: "User",
      id: string,
      source: UserSource | null,
      type: UserType | null,
      email: string | null,
      stripe:  {
        __typename: "Stripe",
        id: string | null,
      } | null,
      notionUser:  {
        __typename: "NotionUser",
        id: string | null,
        familyName: string | null,
        givenName: string | null,
        profilePhoto: string | null,
      } | null,
      authToNotion: boolean | null,
      formsCount: number | null,
      createdAt: string,
      updatedAt: string,
      notionAuthToken: string | null,
      forms:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          createdAt: string,
          updatedAt: string,
          userId: string,
          type: FormType | null,
          name: string,
          data: string | null,
          fields: string | null,
          collection: string | null,
          options:  {
            __typename: "FormOption",
            darkMode: boolean | null,
            color: string | null,
            locked: boolean | null,
          } | null,
          secretToken: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null,
    errorCode: ErrorCode | null,
    errorMsg: string | null,
  },
};

export type CallNotionMutationVariables = {
  form: VerifyFormInput,
  notion: NotionInput,
};

export type CallNotionMutation = {
  callNotion:  {
    __typename: "RespStatusNotion",
    success: boolean,
    data: string | null,
    errorCode: ErrorCode | null,
    errorMsg: string | null,
  },
};

export type Test2MutationVariables = {
  parameter: string,
};

export type Test2Mutation = {
  test2:  {
    __typename: "RespStatusNotion",
    success: boolean,
    data: string | null,
    errorCode: ErrorCode | null,
    errorMsg: string | null,
  },
};

export type CreateAppMutationVariables = {
  input: CreateAppInput,
  condition?: ModelAppConditionInput | null,
};

export type CreateAppMutation = {
  createApp:  {
    __typename: "App",
    id: string,
    usersCount: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAppMutationVariables = {
  input: UpdateAppInput,
  condition?: ModelAppConditionInput | null,
};

export type UpdateAppMutation = {
  updateApp:  {
    __typename: "App",
    id: string,
    usersCount: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAppMutationVariables = {
  input: DeleteAppInput,
  condition?: ModelAppConditionInput | null,
};

export type DeleteAppMutation = {
  deleteApp:  {
    __typename: "App",
    id: string,
    usersCount: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser:  {
    __typename: "User",
    id: string,
    source: UserSource | null,
    type: UserType | null,
    email: string | null,
    stripe:  {
      __typename: "Stripe",
      id: string | null,
    } | null,
    notionUser:  {
      __typename: "NotionUser",
      id: string | null,
      familyName: string | null,
      givenName: string | null,
      profilePhoto: string | null,
    } | null,
    authToNotion: boolean | null,
    formsCount: number | null,
    createdAt: string,
    updatedAt: string,
    notionAuthToken: string | null,
    forms:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        createdAt: string,
        updatedAt: string,
        userId: string,
        type: FormType | null,
        name: string,
        data: string | null,
        fields: string | null,
        collection: string | null,
        options:  {
          __typename: "FormOption",
          darkMode: boolean | null,
          color: string | null,
          locked: boolean | null,
        } | null,
        secretToken: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser:  {
    __typename: "User",
    id: string,
    source: UserSource | null,
    type: UserType | null,
    email: string | null,
    stripe:  {
      __typename: "Stripe",
      id: string | null,
    } | null,
    notionUser:  {
      __typename: "NotionUser",
      id: string | null,
      familyName: string | null,
      givenName: string | null,
      profilePhoto: string | null,
    } | null,
    authToNotion: boolean | null,
    formsCount: number | null,
    createdAt: string,
    updatedAt: string,
    notionAuthToken: string | null,
    forms:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        createdAt: string,
        updatedAt: string,
        userId: string,
        type: FormType | null,
        name: string,
        data: string | null,
        fields: string | null,
        collection: string | null,
        options:  {
          __typename: "FormOption",
          darkMode: boolean | null,
          color: string | null,
          locked: boolean | null,
        } | null,
        secretToken: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateFormMutationVariables = {
  input: CreateFormInput,
  condition?: ModelFormConditionInput | null,
};

export type CreateFormMutation = {
  createForm:  {
    __typename: "Form",
    id: string,
    createdAt: string,
    updatedAt: string,
    userId: string,
    type: FormType | null,
    name: string,
    data: string | null,
    fields: string | null,
    collection: string | null,
    options:  {
      __typename: "FormOption",
      darkMode: boolean | null,
      color: string | null,
      locked: boolean | null,
    } | null,
    secretToken: string | null,
  } | null,
};

export type DeleteFormMutationVariables = {
  input: DeleteFormInput,
  condition?: ModelFormConditionInput | null,
};

export type DeleteFormMutation = {
  deleteForm:  {
    __typename: "Form",
    id: string,
    createdAt: string,
    updatedAt: string,
    userId: string,
    type: FormType | null,
    name: string,
    data: string | null,
    fields: string | null,
    collection: string | null,
    options:  {
      __typename: "FormOption",
      darkMode: boolean | null,
      color: string | null,
      locked: boolean | null,
    } | null,
    secretToken: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser:  {
    __typename: "User",
    id: string,
    source: UserSource | null,
    type: UserType | null,
    email: string | null,
    stripe:  {
      __typename: "Stripe",
      id: string | null,
    } | null,
    notionUser:  {
      __typename: "NotionUser",
      id: string | null,
      familyName: string | null,
      givenName: string | null,
      profilePhoto: string | null,
    } | null,
    authToNotion: boolean | null,
    formsCount: number | null,
    createdAt: string,
    updatedAt: string,
    notionAuthToken: string | null,
    forms:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        createdAt: string,
        updatedAt: string,
        userId: string,
        type: FormType | null,
        name: string,
        data: string | null,
        fields: string | null,
        collection: string | null,
        options:  {
          __typename: "FormOption",
          darkMode: boolean | null,
          color: string | null,
          locked: boolean | null,
        } | null,
        secretToken: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateFormMutationVariables = {
  input: UpdateFormInput,
  condition?: ModelFormConditionInput | null,
};

export type UpdateFormMutation = {
  updateForm:  {
    __typename: "Form",
    id: string,
    createdAt: string,
    updatedAt: string,
    userId: string,
    type: FormType | null,
    name: string,
    data: string | null,
    fields: string | null,
    collection: string | null,
    options:  {
      __typename: "FormOption",
      darkMode: boolean | null,
      color: string | null,
      locked: boolean | null,
    } | null,
    secretToken: string | null,
  } | null,
};

export type GetAppQueryVariables = {
  id: string,
};

export type GetAppQuery = {
  getApp:  {
    __typename: "App",
    id: string,
    usersCount: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAppsQueryVariables = {
  filter?: ModelAppFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppsQuery = {
  listApps:  {
    __typename: "ModelAppConnection",
    items:  Array< {
      __typename: "App",
      id: string,
      usersCount: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser:  {
    __typename: "User",
    id: string,
    source: UserSource | null,
    type: UserType | null,
    email: string | null,
    stripe:  {
      __typename: "Stripe",
      id: string | null,
    } | null,
    notionUser:  {
      __typename: "NotionUser",
      id: string | null,
      familyName: string | null,
      givenName: string | null,
      profilePhoto: string | null,
    } | null,
    authToNotion: boolean | null,
    formsCount: number | null,
    createdAt: string,
    updatedAt: string,
    notionAuthToken: string | null,
    forms:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        createdAt: string,
        updatedAt: string,
        userId: string,
        type: FormType | null,
        name: string,
        data: string | null,
        fields: string | null,
        collection: string | null,
        options:  {
          __typename: "FormOption",
          darkMode: boolean | null,
          color: string | null,
          locked: boolean | null,
        } | null,
        secretToken: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      source: UserSource | null,
      type: UserType | null,
      email: string | null,
      stripe:  {
        __typename: "Stripe",
        id: string | null,
      } | null,
      notionUser:  {
        __typename: "NotionUser",
        id: string | null,
        familyName: string | null,
        givenName: string | null,
        profilePhoto: string | null,
      } | null,
      authToNotion: boolean | null,
      formsCount: number | null,
      createdAt: string,
      updatedAt: string,
      notionAuthToken: string | null,
      forms:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          createdAt: string,
          updatedAt: string,
          userId: string,
          type: FormType | null,
          name: string,
          data: string | null,
          fields: string | null,
          collection: string | null,
          options:  {
            __typename: "FormOption",
            darkMode: boolean | null,
            color: string | null,
            locked: boolean | null,
          } | null,
          secretToken: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ListUserFormsQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserFormsQuery = {
  listUserForms:  {
    __typename: "ModelFormConnection",
    items:  Array< {
      __typename: "Form",
      id: string,
      createdAt: string,
      updatedAt: string,
      userId: string,
      type: FormType | null,
      name: string,
      data: string | null,
      fields: string | null,
      collection: string | null,
      options:  {
        __typename: "FormOption",
        darkMode: boolean | null,
        color: string | null,
        locked: boolean | null,
      } | null,
      secretToken: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetFormQueryVariables = {
  id: string,
};

export type GetFormQuery = {
  getForm:  {
    __typename: "Form",
    id: string,
    createdAt: string,
    updatedAt: string,
    userId: string,
    type: FormType | null,
    name: string,
    data: string | null,
    fields: string | null,
    collection: string | null,
    options:  {
      __typename: "FormOption",
      darkMode: boolean | null,
      color: string | null,
      locked: boolean | null,
    } | null,
    secretToken: string | null,
  } | null,
};

export type ListFormsQueryVariables = {
  filter?: ModelFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFormsQuery = {
  listForms:  {
    __typename: "ModelFormConnection",
    items:  Array< {
      __typename: "Form",
      id: string,
      createdAt: string,
      updatedAt: string,
      userId: string,
      type: FormType | null,
      name: string,
      data: string | null,
      fields: string | null,
      collection: string | null,
      options:  {
        __typename: "FormOption",
        darkMode: boolean | null,
        color: string | null,
        locked: boolean | null,
      } | null,
      secretToken: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type OnCreateAppSubscription = {
  onCreateApp:  {
    __typename: "App",
    id: string,
    usersCount: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAppSubscription = {
  onUpdateApp:  {
    __typename: "App",
    id: string,
    usersCount: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAppSubscription = {
  onDeleteApp:  {
    __typename: "App",
    id: string,
    usersCount: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFormSubscriptionVariables = {
  userId?: string | null,
};

export type OnCreateFormSubscription = {
  onCreateForm:  {
    __typename: "Form",
    id: string,
    createdAt: string,
    updatedAt: string,
    userId: string,
    type: FormType | null,
    name: string,
    data: string | null,
    fields: string | null,
    collection: string | null,
    options:  {
      __typename: "FormOption",
      darkMode: boolean | null,
      color: string | null,
      locked: boolean | null,
    } | null,
    secretToken: string | null,
  } | null,
};

export type OnUpdateFormSubscriptionVariables = {
  userId?: string | null,
};

export type OnUpdateFormSubscription = {
  onUpdateForm:  {
    __typename: "Form",
    id: string,
    createdAt: string,
    updatedAt: string,
    userId: string,
    type: FormType | null,
    name: string,
    data: string | null,
    fields: string | null,
    collection: string | null,
    options:  {
      __typename: "FormOption",
      darkMode: boolean | null,
      color: string | null,
      locked: boolean | null,
    } | null,
    secretToken: string | null,
  } | null,
};

export type OnDeleteFormSubscriptionVariables = {
  userId?: string | null,
};

export type OnDeleteFormSubscription = {
  onDeleteForm:  {
    __typename: "Form",
    id: string,
    createdAt: string,
    updatedAt: string,
    userId: string,
    type: FormType | null,
    name: string,
    data: string | null,
    fields: string | null,
    collection: string | null,
    options:  {
      __typename: "FormOption",
      darkMode: boolean | null,
      color: string | null,
      locked: boolean | null,
    } | null,
    secretToken: string | null,
  } | null,
};
