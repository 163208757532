import React, {
    CSSProperties,
    useEffect,
    useCallback,
    useContext,
    useState,
  } from "react"

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Boxfc,
    Boxf,
    H4,
    H3,
    H2,
    Button,
    BoxCont,
    BoxVert,
    BoxHori,
    TextField,
    Box,
    Property,
  } from "../Reusable"
import {Collection, Workspace} from '../types'
import { throttle } from "../utils"
import { SelectFieldNoAutoComplete,SelectedPageOption, PageIcon } from "./Field"
const CircularProgress = require("@material-ui/core/CircularProgress").default
const Autocomplete = require("@material-ui/lab/Autocomplete").default

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  
  function isSameDay(inputDate: Date, offsetDay = 0) {
    // Get today's date
    var current = new Date()
    var nDay = new Date(current.getTime() + 86400000 * offsetDay)
  
    // call setHours to take the time out of the comparison
    return inputDate.setHours(0, 0, 0, 0) == nDay.setHours(0, 0, 0, 0)
  }
  
  function isToday(inputDate: Date, offsetDay = 0) {
    return isSameDay(inputDate, 0)
  }
  
  function isYesterday(inputDate: Date) {
    return isSameDay(inputDate, -1)
  }
  
  function isTomorrow(inputDate: Date) {
    return isSameDay(inputDate, 1)
  }
  
  export const formatDate = (input: string) => {
    const date = new Date(input)
    if (isToday(date)) return "@Today"
    if (isYesterday(date)) return "@Yesterday"
    if (isTomorrow(date)) return "@Tomorrow"
    const month = date.getMonth()
    return `${months[month]} ${date.getDate()}, ${date.getFullYear()}`
  }
  
  function getDate(val) {
    let startDate
    switch (val.type) {
      case "datetime":
      case "date":
        startDate = val.start_date
        return formatDate(startDate)
      case "daterange":
      case "datetimerange":
        // Example: Jul 31, 2010 → Jul 31, 2020
        startDate = val.start_date
        const endDate = val.end_date
        return `${formatDate(startDate)} → ${formatDate(endDate)}`
      default:
        return null
    }
  }
  
export function getDepVal(properties, key, defaultValue) {
    if (
      !(
        properties != null &&
        properties[key] != null &&
        Array.isArray(properties[key]) &&
        properties[key].every((e) => Array.isArray(e))
      )
    ) {
      return defaultValue
    }
  
    let s = ""
    properties[key].forEach(([text, decorations]) => {
      if (!decorations) {
        s += text
        return
      }
      const x = decorations.flatMap((d) => {
        switch (d[0]) {
          case "d":
            const value = d[1]
            const content = getDate(value)
            return [content]
          default:
            return []
        }
      })
      if (x.length) {
        s += x.join(" ")
      }
    })
    return s
  }

const PropretyName = ({
    option,
    size = "medium",
  }: {
    size?: "small" | "medium"
    option: any
  }) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 25 }}>
        </div>
        <div
          style={{
           fontSize: "14px" 
          }}
        >
          {option.name}
        </div>
      </div>
    )
  }
  
export const SpaceSelect = ({
    spaceId,
    onSelectSpaceId,
    disabled,
    workspaces,
    refreshWorkspaces,
  }: {
    workspaces,
    refreshWorkspaces:()=>Promise<void>,
    spaceId: null|string
    disabled?: boolean;
    onSelectSpaceId: (spaceId: string) => void
  }) => {
  const [loading,setLoading] = useState(false);

  return (
      <Property
    label={"Workspace"}
    labelWidth={"150px"}
    >
       <SelectFieldNoAutoComplete
          disabled={disabled}
          value={spaceId || ''}
          loading={loading}
          options={workspaces}
          onOpen={async () => {
            setLoading(true);
            await refreshWorkspaces();
            setLoading(false);
          }}
          onSelectOption={(id) => {
            onSelectSpaceId(id)
          }}
          uniqueKey="id"
          renderValue={(id) => {
            const d = workspaces.find((e) => e.id == id)
            if (!d) return <SelectedPageOption
            iconComp={<></>}
            name={"Loading..."}
            className="selectedDataOptionRow"
          />;
            return (
              <SelectedPageOption
                iconComp={<></>}
                name={d?.name || ""}
                className="selectedDataOptionRow"
              />
            )
          }}
          renderOption={(space) => (
            <SelectedPageOption
              name={space?.name || ""}
              className="selectedDataOptionRow"
            />
          )}
          style={{ width: "250px", height: "40px" }}
        />
    </Property>
  )
  }
  const Parents = ({ parents }: { parents: { name: string }[] }) => {
    return (
      <div style={{ color: "grey", fontSize: 11, fontWeight: 350 }}>
        {parents.length >= 1 && <>{parents[0].name}</>}
        {parents.length >= 3 ? ` / ... / ` : parents.length == 2 ? ` / ` : ``}
        {parents.length >= 2 && <>{parents[parents.length - 1].name}</>}
      </div>
    )
  }

  export const CollectionSelect = ({
    collection,
    onSelectCollection,
    workspace,
    disabled,
    userId,
    searchCollections,
  }: {
    collection: Collection | null
    userId:string
    workspace:Workspace
    disabled?: boolean
    searchCollections:(searchText:string,workspace:Workspace)=>Promise<Collection>
    onSelectCollection: (col: Collection) => void
  }) => {
    const [open, setOpen] = React.useState(false)
    const [active, setActive] = useState(false)
    const [workspaceId, setWorkspaceId] = useState(workspace.id)
    const [focus, setFocus] = useState(false)
    const [options, setOptions] = React.useState<Collection[]>([
      ...(collection ? [collection] : []),
    ])
    const [inputValue, setInputValue] = useState("")
    const loading = open && active
    const updateOptions = (fetchedCollections: Collection[]) => {
      let ids = {}
      let opts = [...fetchedCollections]
      fetchedCollections.forEach((e) => {
        ids[e.id] = true
      })
      //print("got", ids)
      for (let i = 0; i < options.length && i < 70; i++) {
        if (!(options[i].id in ids)) {
          opts.push(options[i])
        }
      }
      setOptions(opts)
    }
  
    const searchCollectionsThrottled: (v: string) => Promise<Collection[]> = React.useMemo(
      () =>
        throttle(async function (v) {
          const x = await searchCollections(v, workspace)
          return x
        }, 1000),
      [workspace]
    ) as any
  
    useEffect(() => {
      if (collection) {
        setInputValue(getDepVal(collection, "name", "Untitled"))
      }
    }, [collection])
  
    useEffect(()=>{
      if (workspaceId != workspace.id){
        setOptions([]);
      }
      setWorkspaceId(workspace.id);
    },[workspace]);
  
    useEffect(() => {
      setFocus(false)
      setActive(true)
      ;(async () => {
        //print(`calling... '${inputValue}'`, )
        const x: Collection[] | undefined = await searchCollectionsThrottled(inputValue)
        if (x) {
          updateOptions(x)
          setActive(false)
        }
      })()
    }, [inputValue, focus])
  
    return (
      <>
       <Property
    label={"Database"}
    labelWidth={"150px"}
    >
<Autocomplete
              size="small"
              disabled={disabled}
              style={{ maxWidth: 300,minWidth:'300px' }}
              inputValue={inputValue}
              onInputChange={(ev, value) => setInputValue(value)}
              value={collection}
              open={open}
              onOpen={() => {
                setOpen(true)
              }}
              loading={loading}
              openOnFocus={true}
              onFocus={() => {
                setFocus(true)
              }}
              onClose={() => {
                setOpen(false)
              }}
              onChange={(
                ev,
                value,
                reason:
                  | "create-option"
                  | "select-option"
                  | "remove-option"
                  | "blur"
                  | "clear"
              ) => {
                console.log("select database", value, reason)
                onSelectCollection && onSelectCollection(value)
              }}
              getOptionSelected={(option, value) =>
                getDepVal(option, "name", "Untitled") ===
                getDepVal(value, "name", "Untitled")
              }
              renderOption={(option) => {
                //console.log("got", option)
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PageIcon
                        icon={option.icon}
                        pageId={option.id}
                        userId={userId}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>{getDepVal(option, "name", "Untitled")}</div>
                        <Parents parents={option.parents || []} />
                      </div>
                    </div>
                  </>
                )
              }}
              getOptionLabel={(option) => getDepVal(option, "name", "Untitled")}
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width: "250px" }}
                  placeholder="Search Database..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
    </Property>
      </>
    )
  }