import {GetAppQueryVariables,GetAppQuery,ListAppsQueryVariables,ListAppsQuery,GetUserQueryVariables,GetUserQuery,ListUsersQueryVariables,ListUsersQuery,GetFormQueryVariables,GetFormQuery,ListFormsQueryVariables,ListFormsQuery,ListUserFormsQueryVariables,ListUserFormsQuery} from '../apiTypes';

export interface ExtQuery<Var,Resp> {
  name:string;
  content:string;
};

export const getApp:ExtQuery<GetAppQueryVariables,GetAppQuery["getApp"]> = {name:"getApp", content:`
  query GetApp($id: ID!) {
    getApp(id: $id) {
      id
      usersCount
      createdAt
      updatedAt
    }
  }
`}
export const listApps:ExtQuery<ListAppsQueryVariables,ListAppsQuery["listApps"]> = {name:"listApps", content:`
  query ListApps(
    $filter: ModelAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usersCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`}
export const getUser:ExtQuery<GetUserQueryVariables,GetUserQuery["getUser"]> = {name:"getUser", content:`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      source
      type
      email
      stripe {
        id
      }
      notionUser {
        id
        familyName
        givenName
        profilePhoto
      }
      authToNotion
      formsCount
      createdAt
      updatedAt
      notionAuthToken
      forms {
        items {
          id
          createdAt
          updatedAt
          userId
          type
          name
          secretToken
          data
          fields
          collection
          options {
            darkMode
            color
            locked
          }
        }
        nextToken
      }
    }
  }
`}
export const listUsers:ExtQuery<ListUsersQueryVariables,ListUsersQuery["listUsers"]> = {name:"listUsers", content:`
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        source
        type
        email
        stripe {
          id
        }
        notionUser {
          id
          familyName
          givenName
          profilePhoto
        }
        authToNotion
        formsCount
        createdAt
        updatedAt
        notionAuthToken
        forms {
          items {
            id
            createdAt
            updatedAt
            userId
            type
            name
            secretToken
            data
            fields
            collection
            options {
              darkMode
              color
              locked
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`}
export const getForm:ExtQuery<GetFormQueryVariables,GetFormQuery["getForm"]> = {name:"getForm", content:`
  query GetForm($id: ID!) {
    getForm(id: $id) {
      id
      createdAt
      updatedAt
      userId
      type
      name
      secretToken
      data
      fields
      collection
      options {
        darkMode
        color
        locked
      }
    }
  }
`}
export const listForms:ExtQuery<ListFormsQueryVariables,ListFormsQuery["listForms"]> = {name:"listForms", content:`
  query ListForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        userId
        type
        name
        secretToken
        data
        fields
        collection
        options {
          darkMode
          color
          locked
        }
      }
      nextToken
    }
  }
`}
export const listUserForms:ExtQuery<ListUserFormsQueryVariables,ListUserFormsQuery["listUserForms"]> = {name:"listUserForms", content:`
  query ListUserForms(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserForms(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        userId
        type
        name
        secretToken
        data
        fields
        collection
        options {
          darkMode
          color
          locked
        }
      }
      nextToken
    }
  }
`}